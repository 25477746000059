import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { equals } from 'ramda';
import useTranslation from 'next-translate/useTranslation';
import { useUpdateModal } from '@zydalabs/storefront-components';

import {
  useCheckSupportedZone,
  useFetchNearestZoneTemplateCallback,
  useLayout,
  useShowAddress,
  useStoreSettings,
} from 'service/hooks';
import { Address, Area, Branch, DeliveryZoneMs } from 'service/types';
import { useUpdateCart } from 'modules/productsModule/hooks';
import { FULFILLMENT_MODES, URLS } from 'common/constants';
import ServiceConfigs from 'service/config';
import { isBranchBusyAtDateTime } from 'modules/checkoutModule/utils';
import { DateTime } from 'luxon';
import { checkCalculatedOrderTimeInit } from 'modules/locationsModule/screens/EstimatedTimePage/utils';
import {
  useGlobalOrderTypeAndTime,
  usePersistedSelectedCoordinates,
  usePersistedUserAddressId,
  usePersistedSupportedDeliveryZone,
  usePersistedAreaTitle,
} from '.';
import { changeRoute, customHistory, replaceRoute } from '../utils';
import { useFulfillmentSettings } from '../../contexts';
import { getGeoLocationInfo } from '../../modules/userModule/utils/getGeoLocationInfo';
import { formatDisplayedAddress } from '../../modules/userModule/utils';
import { CoordsType } from '../types';

const useSelectAddressOrArea = (onSuccess?: (string) => void, onCurLocationSelected?) => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // persisted data
  const {
    area: currentArea,
    setAreaId,
    branch,
    setBranchId,
    orderMode,
    setOrderMode,
    isBranchLoading,
  } = useFulfillmentSettings();
  const [persistedUserAddressId, setPersistedUserAddressId, removePersistedUserAddressId] = usePersistedUserAddressId();
  const [, setPersistedAreaTitle] = usePersistedAreaTitle();
  const { t, lang } = useTranslation('address');

  const [selectedCoords, setPersistedSelectedCoordinates, removePersistedSelectedCoordinates] =
    usePersistedSelectedCoordinates();
  const [persistedSupportedDeliveryZone, setPersistedSupportedDeliveryZone] = usePersistedSupportedDeliveryZone();
  const useDeliveryzonesMs = ServiceConfigs?.getUseDeliveryzonesMs();

  const [calculatedOrderTime] = useGlobalOrderTypeAndTime();
  const { data: addressDetails } = useShowAddress({
    addressId: persistedUserAddressId,
  });
  const { data: layoutSettings } = useLayout();
  const updateModal = useUpdateModal();

  const isUsingDZMS = ServiceConfigs?.useDeliveryzonesMs;
  const geoPoint = selectedCoords ? [selectedCoords?.lng, selectedCoords?.lat] : [29.363895747, 47.983437979];
  const checkSupportedZoneVars =
    isUsingDZMS && layoutSettings?.id ? { restaurantReferenceId: layoutSettings?.id, geoPoint } : null;
  const { data: supportedZoneResponse } = useCheckSupportedZone(checkSupportedZoneVars);
  const fetchNearestZoneTemplate = useFetchNearestZoneTemplateCallback();

  const updateCart = useUpdateCart();
  const { data: storeSettings } = useStoreSettings();

  const isUserSelectedAreaOrDeliveryZone = useRef(false);
  const isUserSelectedCoordinates = useRef(false);
  const isUserSelectedAddress = useRef(false);
  const isCalculatedOrderTimeFullyInitialized = useRef(false);
  const isDelivery = orderMode === FULFILLMENT_MODES.DELIVERY;
  const isScheduledAvailable = storeSettings?.setting?.scheduleOrdersEnabled ?? false;

  const handleOnDeliveryZoneSelected = async (
    deliveryZone: DeliveryZoneMs,
    isAddressDeliveryZone = false,
    address: Address = undefined,
  ) => {
    const deliveryZoneBranchId = deliveryZone?.branchReferenceId?.toString();
    if (equals(deliveryZone, persistedSupportedDeliveryZone)) {
      onSuccess?.(branch?.id);
      return;
    }
    isUserSelectedAreaOrDeliveryZone.current = true;
    await setPersistedSupportedDeliveryZone(deliveryZone);
    if (branch?.id !== deliveryZoneBranchId) await setBranchId(deliveryZoneBranchId);
    if (calculatedOrderTime?.isCartAvailable) {
      const updateCartVars = {
        branchId: deliveryZoneBranchId,
        deliveryZone: {
          ...deliveryZone,
          branchId: deliveryZone?.branchReferenceId?.toString(),
          branchReferenceId: undefined,
          restaurantId: deliveryZone?.restaurantReferenceId?.toString(),
          restaurantReferenceId: undefined,
          geoShape: JSON.stringify(deliveryZone?.geoShape),
          userLat: address?.lat ? parseFloat(address.lat) : selectedCoords?.lat,
          userLng: address?.lng ? parseFloat(address.lng) : selectedCoords?.lng,
        },
        deliveryType: FULFILLMENT_MODES.DELIVERY,
        ...(isAddressDeliveryZone &&
          persistedUserAddressId && {
            addressId: address?.id || persistedUserAddressId,
          }),
      };
      await updateCart(updateCartVars);
    }
  };

  const handleOnBusyZoneSelected = (
    coords: CoordsType,
    zoneBranch: Branch,
    handleDeliveryZoneSelection: (coords: CoordsType) => void,
  ) => {
    const { busyMode, busyFrom, busyUntil, pickupEnabled } = zoneBranch || {};
    const isPickupBusy = isBranchBusyAtDateTime(DateTime.now(), FULFILLMENT_MODES.PICKUP, busyMode, busyFrom, busyUntil);

    const confirmButtonText = isScheduledAvailable ? t('branches:branchBusy.scheduleOrder') : t('branches:continue');
    const cancelButtonText =
      isPickupBusy || !pickupEnabled ? t('branches:branchBusy.backToHomepage') : t('branches:branchBusy.switchToPickup');
    const handleConfirm = () => {
      handleDeliveryZoneSelection(coords);
      if (isScheduledAvailable) {
        changeRoute({ pathname: URLS.ESTIMATED_TIME });
      }
      updateModal({ isOpen: false });
    };
    const handleCancel = () => {
      removePersistedSelectedCoordinates();
      const route =
        isPickupBusy || !pickupEnabled ? URLS.HOME : { pathname: URLS.ORDER_MODE, query: { mode: FULFILLMENT_MODES.PICKUP } };
      replaceRoute(route);
      updateModal({ isOpen: false });
    };

    const modalConfig = {
      isOpen: true,
      showCloseIcon: false,
      title: t('branches:branchBusy.title'),
      confirmButtonText,
      cancelButtonText,
      description: t('branches:branchBusy.description'),
      onConfirm: handleConfirm,
      onCancel: handleCancel,
    };

    updateModal(modalConfig);
  };

  const handleOnAreaSelected = async (area: Area, isAddressArea = false, addressId = persistedUserAddressId) => {
    const { deliveryZone } = area || {};
    const deliveryZoneBranchId = deliveryZone?.branchId?.toString();

    setAreaId({ id: area?.id });
    setOrderMode(FULFILLMENT_MODES.DELIVERY);

    if (branch?.id !== deliveryZoneBranchId) setBranchId(deliveryZoneBranchId);
    removePersistedSelectedCoordinates();

    // TODO update persisted country data with current country details
    if (calculatedOrderTime?.isCartAvailable) {
      const updateCartVars = {
        branchId: deliveryZoneBranchId,
        areaId: area?.id,
        deliveryType: FULFILLMENT_MODES.DELIVERY,
        ...(isAddressArea &&
          addressId && {
            addressId,
          }),
      };
      await updateCart(updateCartVars);
    }
    isUserSelectedAreaOrDeliveryZone.current = true;
    calculatedOrderTime?.recalculate?.();
  };

  const handleOnAddressSelected = async address => {
    await setOrderMode(FULFILLMENT_MODES.DELIVERY);
    const { id, lat, lng, area, deliveryZone } = address || {};
    if (useDeliveryzonesMs) {
      await setPersistedUserAddressId(id);
      await setPersistedSelectedCoordinates({ lat: parseFloat(lat), lng: parseFloat(lng) });
      await handleOnDeliveryZoneSelected(deliveryZone, true, address);
    } else if (currentArea?.id === area?.id) {
      setPersistedUserAddressId(id);
      onSuccess?.(branch?.id);
    } else {
      // We need to set the ref before setting the states to overcome running useEffects without executing whats inside it
      isUserSelectedAddress.current = true;
      await setPersistedUserAddressId(id);
    }
  };

  const handleSelectCoordinates = (coords: CoordsType) => {
    /*
     * This handles the chosen coordinates despite of the delivery zone
     * 1) persist selected coordinates, and order mode
     * 2) persist area name and order mode type
     * 3) remove persisted address if any
     */
    removePersistedUserAddressId();
    setOrderMode(FULFILLMENT_MODES.DELIVERY);
    setPersistedSelectedCoordinates(coords);

    fetchNearestZoneTemplate({ geoPoint: [coords.lng, coords.lat] }).then(nearestZoneTemplate => {
      if (nearestZoneTemplate?.nearestZoneTemplate)
        setPersistedAreaTitle(
          formatDisplayedAddress(
            nearestZoneTemplate.nearestZoneTemplate?.[lang === 'en' ? 'areaTitleEn' : 'areaTitleAr'],
            nearestZoneTemplate.nearestZoneTemplate?.[lang === 'en' ? 'cityTitleEn' : 'cityTitleAr'],
          ),
        );
      else
        getGeoLocationInfo(coords.lat.toString(), coords.lng.toString(), router.locale).then(locationInfo => {
          setPersistedAreaTitle(formatDisplayedAddress(locationInfo?.response?.area, locationInfo?.response?.governate));
        });
    });
  };

  const handleSelectCurrentLocation = async () => {
    setIsLoading(true);
    navigator?.geolocation.getCurrentPosition(
      async ({ coords: { latitude, longitude } }) => {
        const coords = { lat: latitude, lng: longitude };
        if (equals(coords, selectedCoords)) {
          setIsLoading(false);
          onCurLocationSelected();
          changeRoute({
            pathname: URLS.ORDER_DELIVERY,
          });
          return;
        }
        await setPersistedSelectedCoordinates(coords);
        isUserSelectedCoordinates.current = true;
      },
      () => {
        onCurLocationSelected();
        changeRoute({ pathname: URLS.ORDER_DELIVERY, query: { showLocationIsDenined: true } });
      },
    );
  };

  useEffect(() => {
    if (!isDelivery) return;

    const selectedAddressAreaIsDifferentThanCartArea =
      addressDetails?.id &&
      isUserSelectedAddress.current &&
      !useDeliveryzonesMs &&
      addressDetails?.area?.id !== currentArea?.id;

    // this was for fixing a bug, after selecting an address, the areaId and branchId were set to undefined
    // TODO the next line should be removed in the future, maybe after fully migrating to new DZMS
    const areaAccidentallyRemoved = !useDeliveryzonesMs && addressDetails?.id && !currentArea;
    if (selectedAddressAreaIsDifferentThanCartArea || areaAccidentallyRemoved) {
      isUserSelectedAddress.current = false;
      handleOnAreaSelected(addressDetails?.area, true, addressDetails?.id);
    }
  }, [isDelivery, addressDetails?.id, currentArea?.id]);

  useEffect(() => {
    // select current location if within delivery zone or redirect to map page
    if (supportedZoneResponse && isUserSelectedCoordinates.current) {
      isUserSelectedCoordinates.current = false;
      onCurLocationSelected();
      if (supportedZoneResponse?.supportedDeliveryZone) {
        handleSelectCoordinates(selectedCoords);
        const supportedDZ = supportedZoneResponse?.supportedDeliveryZone;
        handleOnDeliveryZoneSelected({
          ...supportedDZ,
          id: supportedDZ?.id,
          countryId: supportedDZ?.countryId,
          minimumOrder: supportedDZ?.minimumOrder,
          deliveryFee: supportedDZ?.deliveryFee,
          deliveryTime: supportedDZ?.deliveryTime,
        });
      } else {
        changeRoute({
          pathname: URLS.ORDER_DELIVERY,
        });
      }
      setIsLoading(false);
    }
  }, [JSON.stringify(supportedZoneResponse)]);

  useEffect(() => {
    if (calculatedOrderTime) {
      isCalculatedOrderTimeFullyInitialized.current = checkCalculatedOrderTimeInit(calculatedOrderTime);
    }
  }, [calculatedOrderTime]);

  useEffect(() => {
    if (
      !isUserSelectedAreaOrDeliveryZone.current ||
      !calculatedOrderTime ||
      calculatedOrderTime.isLoading ||
      !isCalculatedOrderTimeFullyInitialized.current ||
      isBranchLoading
    )
      return;
    const { fulfillmentTimeType, isScheduledEnabled, isASAPAvailable } = calculatedOrderTime || {};

    const isActionSheet = [URLS.CHECKOUT, URLS.PRODUCT_DETAILS, URLS.HOME].includes(router.pathname);
    isUserSelectedAreaOrDeliveryZone.current = false;

    if (fulfillmentTimeType) {
      onSuccess?.(branch?.id);
    } else if (isScheduledEnabled || isASAPAvailable) {
      if (isActionSheet) changeRoute({ pathname: URLS.ESTIMATED_TIME, query: { ...router.query } });
      else replaceRoute({ pathname: URLS.ESTIMATED_TIME, query: { ...router.query } });
    } else if (!isActionSheet) {
      customHistory.handleOrderModeRouting({
        branchId: branch?.id,
      });
    }
  }, [calculatedOrderTime, isBranchLoading, isCalculatedOrderTimeFullyInitialized.current]);

  return {
    handleOnAddressSelected,
    handleOnAreaSelected,
    handleOnDeliveryZoneSelected,
    handleSelectCurrentLocation,
    handleSelectCoordinates,
    handleOnBusyZoneSelected,
    isLoading,
  };
};

export default useSelectAddressOrArea;
