import { gql } from 'graphql-request';
import fragmentCartMutationResponse from 'service/fragments/cartFragmentMutationResponse';

const updateCartMutation = gql`
  mutation updateCart(
    $subdomain: String!
    $cartId: String!
    $branchId: ID!
    $areaId: ID
    $deliveryZone: DeliveryZoneMs
    $deliveryType: String!
    $voucherCode: String
    $addressId: ID
    $scheduledTime: ISO8601DateTime
    $isManualOrder: Boolean
  ) {
    updateCart(
      subdomain: $subdomain
      cartId: $cartId
      branchId: $branchId
      areaId: $areaId
      deliveryZone: $deliveryZone
      deliveryType: $deliveryType
      voucherCode: $voucherCode
      addressId: $addressId
      isManualOrder: $isManualOrder
    ) {
      ...returnedCart
    }
  }
  ${fragmentCartMutationResponse}
`;

export default updateCartMutation;
